import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const renderInputField = field => {
  if (field.type == 'checkbox')
    return (
      <div
        className={`pt-3 form-builder-grid ${
          field.choices.length > 10 ? 'flex-row flex-wrap' : 'flex-column'
        }`}
      >
        {field.choices.map((choice, index) => (
          <label key={`${index}-${choice}`} htmlFor={choice} className='mr-3 d-flex items-center'>
            <Field
              id={choice}
              type='checkbox'
              name={field.name}
              value={choice}
              style={{ marginRight: '10px', width: '1.2rem', height: '1.2rem' }}
              disabled
            />
            {choice}
          </label>
        ))}
      </div>
    );
  if (field.type == 'radio')
    return (
      <div className='pt-3 form-builder-grid'>
        {field.choices.map((choice, index) => (
          <label key={`${index}-${choice}`} htmlFor={choice} className='d-flex'>
            <Field
              id={choice}
              type='radio'
              value={choice}
              name={field.name}
              style={{ marginRight: '10px', width: '1.2rem' }}
              disabled
            />
            {choice}
          </label>
        ))}
      </div>
    );
  if (field.type == 'heading')
    return (
      <h4
        className={`form-builder-heading ${
          field.text_decoration?.italic ? 'font-italic' : ''
        } ${field.text_decoration?.underline ? 'font-underline' : ''} ${
          field.text_decoration?.bold ? 'font-bold' : ''
        }`}
      >
        {field.title}
      </h4>
    );
  if (field.type == 'email')
    return (
      <Field type='email' name={field.name} className='form-control' disabled />
    );
  return (
    <Field
      type={field.type == 'number' ? 'number' : 'text'}
      name={field.name}
      className='form-control'
      disabled
    />
  );
};

const DraggableField = ({ id, field, index, handleDelete, handleEdit }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`draggable-field flex-col-${field.colspan}`}
        >
          <div
            className='form-group'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label htmlFor='title' className='w-100' style={{ flex: 1 }}>
              {field.type !== 'heading' && (
                <span
                  className={`mb-2 ${
                    field.text_decoration?.italic ? 'font-italic' : ''
                  } ${
                    field.text_decoration?.underline ? 'font-underline' : ''
                  } ${field.text_decoration?.bold ? 'font-bold' : ''}`}
                >
                  {field.title}
                </span>
              )}
              {renderInputField(field)}
            </label>
            {field.deleteable && (
              <>
                {!['sendSms', 'signature'].includes(field.name) && (
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      marginLeft: '8px',
                      marginRight: '8px',
                      cursor: 'pointer',
                      color: '#FF4961'
                    }}
                    onClick={() => handleDelete(field.name)}
                  />
                )}
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    cursor: 'pointer',
                    color: '#FDC01A'
                  }}
                  onClick={() => handleEdit(field.name)}
                />
              </>
            )}
            <span className='grippy' {...provided.dragHandleProps} />
          </div>
        </div>
      )}
    </Draggable>
  );
};
export default DraggableField;
