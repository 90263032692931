import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ActionCableProvider,
  ActionCableConsumer
} from 'react-actioncable-provider';
import ActionCable from 'actioncable';
import {
  fetchPatientList,
  filterPatientList,
  toggleActivePatient
} from '../../actions/patients';
import {
  receiveNewMessageSuccess,
  markMessagesRead
} from '../../actions/messages';
import PatientListItem from '../../components/patients/PatientListItem';
import PatientDetails from '../../components/patients/PatientDetails';
import Select from 'react-select';
import MessageBox from '../../components/messages/MessageBox';
import { PRIMARY_COLOR } from '../../constants';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useLocation } from 'react-router-dom';

const Message = props => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [patientDetailsColClass, setPatientDetailsColClass] =
    useState('col-12');
  const [filterText, setFilterText] = useState('');
  const [unreadMessages, setUnreadMessages] = useState(false);
  const [archived, setArchived] = useState(false);
  const [enrolled, setEnrolled] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    props.type === 'unreadMessages' ? 'All Messages' : 'Enrolled Clients'
  );
  const [option, setOption] = useState(
    props.type === 'unreadMessages'
      ? [
          { value: 'unreadMessages', label: 'Unread Messages' },
          { value: 'enrolledClients', label: 'All Messages' }
        ]
      : [
          { value: 'archivedClients', label: 'Archived Clients' },
          { value: 'enrolledClients', label: 'Enrolled Clients' },
          { value: 'unreadMessages', label: 'Unread Messages' }
        ]
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const element = document.querySelector(
      '[data-react-class="patients/PatientIndex"]'
    );
    if (element) element.style.height = '100%';
    if (props.isOwner) {
      props.loadPatientList(props.clinicId);
      setPatientDetailsColClass('col-12 col-lg-10');
    } else {
      props.loadPatient(props.clinicId, props.userId);
    }
  }, []);

  useEffect(() => {
    if (props.activePatient && props.isOwner) {
      const newURL = `${location.pathname}?patient_id=${props.activePatient}`;
      navigate(newURL);
    } else if (
      !props.activePatient &&
      props.selectedPatientId &&
      props.isOwner
    ) {
      props.loadPatient(props.clinicId, props.selectedPatientId);
    }
  }, [props.activePatient, props.selectedPatientId]);

  const changeFilter = filter => {
    setFilterText(filter);
    props.onFilterChange(filter, unreadMessages, archived, enrolled);
  };

  const handleDropDownFilter = e => {
    setSelectedOption(e.label);
    if (e.value === 'unreadMessages') {
      setUnreadMessages(true);
      setEnrolled(false);
      setArchived(false);
      props.onFilterChange(filterText, true, false, false);
    } else if (e.value === 'archivedClients') {
      setUnreadMessages(false);
      setEnrolled(false);
      setArchived(true);
      props.onFilterChange(filterText, false, true, false);
    } else {
      setUnreadMessages(false);
      setEnrolled(true);
      setArchived(false);
      props.onFilterChange(filterText, false, false, true);
    }
  };

  const receivedMessage = (patient, message) => {
    if ($('.message-tab-' + patient.id).hasClass('active')) {
      props.markMessagesRead(
        patient.conversation.id,
        props.isOwner,
        patient.clinic_id
      );
    }
    props.onMessageReceived(message);
  };

  const toggleSidebar = event => {
    event.preventDefault();
    setShowSidebar(!showSidebar);
    setPatientDetailsColClass('col-12');
  };

  let activePatient, patientRows;
  let patientList =
    props.filteredPatientList == null
      ? props.patients
      : props.filteredPatientList;

  if (props.isOwner) {
    patientRows = patientList
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .map(patient => {
        return (
          patient.archive == archived && (
            <PatientListItem
              key={patient.id}
              patient={patient}
              active={patient.id == props.activePatient}
              clinicId={props.clinicId}
              type={'unreadMessage'}
            />
          )
        );
      });

    activePatient = props.patients.find(
      patient => patient.id == props.activePatient
    );
  } else {
    activePatient = props.patients.find(patient => patient.id == props.userId);
  }

  const actionCables = props.patients.map(patient => {
    if (patient.conversation) {
      return (
        <ActionCableConsumer
          key={patient.conversation.id}
          channel={{
            channel: 'ConversationsChannel',
            conversation: patient.conversation.id,
            user_id: props.userId
          }}
          onReceived={receivedMessage.bind(this, patient)}
        />
      );
    }
  });

  let websocketProtocol = window.location.protocol == 'https:' ? 'wss:' : 'ws:';
  const cable = ActionCable.createConsumer(
    websocketProtocol + '//' + window.location.host + '/cable'
  );

  return (
    <ActionCableProvider cable={cable} className='h-100'>
      <div className={props.isOwner ? 'row h-100' : ''}>
        {showSidebar && props.isOwner && (
          <div
            style={{ zIndex: 10 }}
            className={`col-lg-2 col-auto p-0 ${
              showSidebar ? 'show-nav h-100 d-lg-block' : 'hide-nav'
            }`}
          >
            <div
              className={`chat-wrapper h-100 sidenav-wrapper ${
                showSidebar ? 'show-nav' : 'hide-nav'
              }`}
            >
              <div className='flex-grow-1 position-relative overflow-hidden'>
                <div className='row no-gutters h-100'>
                  <div className='chat-sidebox col'>
                    <span className='d-flex w-100 mb-2 justify-content-end pt-2'>
                      <ArrowBackIosIcon
                        fontSize='small'
                        className='d-block d-lg-none nav-icon'
                        onClick={() => setShowSidebar(false)}
                      />
                    </span>
                    <div className='flex-grow-0 px-4'>
                      <h3 className='mt-3 text-center text-dark'>
                        {props.clinicName}
                      </h3>
                      <div className='media align-items-center'>
                        <div className='media-body'>
                          <input
                            type='text'
                            id='patient-filter'
                            className='form-control chat-search my-3'
                            placeholder='Search for Client'
                            value={filterText}
                            onChange={e => changeFilter(e.target.value)}
                          />

                          <div className='clearfix'></div>
                          <div className='form-group'>
                            <Select
                              value={option.filter(
                                option => option.label === selectedOption
                              )}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  text: 'orangered',
                                  primary: PRIMARY_COLOR
                                }
                              })}
                              options={option}
                              onChange={e => handleDropDownFilter(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <hr className='border-light m-0' />
                    </div>
                    <div
                      className='flex-grow-1 position-relative px-4'
                      style={{ height: '20px' }}
                    >
                      <div className='chat-contacts list-group chat-scroll py-3'>
                        {patientRows}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`${patientDetailsColClass} ${
            showSidebar ? 'col-lg-10' : 'order-lg-first col-lg-12'
          } w-100 pr-0 pr-lg-2 scrollable-box`}
        >
          {props.isOwner && (
            <a
              href='#'
              className={`mb-2 ${
                props.isOwner
                  ? 'd-inline-block border px-3 py-2 toggle-sidebar btn btn-primary'
                  : 'd-none'
              }`}
              onClick={toggleSidebar}
            >
              {showSidebar ? (
                <i className='mr-1 fe fe-arrow-left'></i>
              ) : (
                <i className='mr-1 fe fe-arrow-right'></i>
              )}
            </a>
          )}
          {props.type === 'unreadMessages' ? (
            <MessageBox patient={activePatient} />
          ) : (
            <PatientDetails patient={activePatient} />
          )}
        </div>
      </div>
      {actionCables}
    </ActionCableProvider>
  );
};

Message.defaultProps = {
  patients: [],
  clinicId: '',
  csrfToken: '',
  activePatient: null,
  isOwner: false
};

const mapStateToProps = state => {
  return {
    patients: state.patientIndex.patients,
    clinicId: state.patientIndex.clinicId,
    clinicName: state.patientIndex.clinicName,
    activePatient: state.patientIndex.activePatient,
    isOwner: state.patientIndex.isOwner,
    userId: state.patientIndex.userId,
    filteredPatientList: state.patientIndex.filteredPatientList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterChange: (filter, unreadMessages, archived, enrolled) => {
      dispatch(filterPatientList(filter, unreadMessages, archived, enrolled));
    },
    loadPatientList: clinicId => {
      dispatch(fetchPatientList(clinicId, 'client'));
    },
    loadPatient: (clinicId, patientId) => {
      dispatch(toggleActivePatient(clinicId, patientId));
    },
    onMessageReceived: message => {
      dispatch(receiveNewMessageSuccess(message));
    },
    markMessagesRead: (conversationId, isClinic, clinicId) => {
      dispatch(markMessagesRead(conversationId, isClinic, clinicId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
