import axios from 'axios';
import { useState } from 'react';
import TableBody from './TableBody';
import React from 'react';

const ClinicsListTable = props => {
  const { clinics, csrfToken } = props;
  const [clinicsList, setClinicList] = useState(clinics);
  const handleOnChange = (event, clinic, index) => {
    let clinicState = {...clinic, [event.target.name]: event.target.checked}
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    };
    axios
      .patch(
        `/${clinic.id}`,
        {
          scheduler_available: clinicState.scheduler_available,
          wlp_available: clinicState.wlp_available
        },
        { headers }
      )
      .then(response => {
        let list = [...clinics]
        list[index].scheduler_available =
          clinicState.scheduler_available;
        list[index].wlp_available =
          clinicState.wlp_available;
        setClinicList(list);
        toastr.success('Clinic Updated');
      })
      .catch(
        ({
          response: {
            data: { message }
          }
        }) => {
          toastr.error(message);
        }
      );
  };
  return (
    <table>
      <thead className='thead-light'>
        <tr>
          <th>Clinic Id</th>
          <th>Clinic Name</th>
          <th>Owner</th>
          <th>Action</th>
        </tr>
      </thead>
     <TableBody clinicsList={clinicsList} handleOnChange={handleOnChange}/>
    </table>
  );
};

export default ClinicsListTable;
