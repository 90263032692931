import { Link } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import React from 'react';

const TableBody = props => {
  const { clinicsList, handleOnChange } = props;
  return (
    <tbody>
      {clinicsList?.map((clinic, index) => (
        <tr key={clinic.id}>
          <td>{clinic.id}</td>
          <td>{clinic.name}</td>
          <td>{clinic?.owner?.name}</td>
          <td>
            <div className='actions'>
              <Link
                className='btn btn-sm btn-outline-success'
                href={`/${clinic.id}`}
              >
                Visit
              </Link>
              <Form onChange={e => handleOnChange(e, clinic, index)}>
                {['checkbox'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Weight Loss Program '
                      name='wlp_available'
                      type={type}
                      checked={clinic.wlp_available}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      inline
                      label='Scheduler'
                      name='scheduler_available'
                      type={type}
                      checked={clinic.scheduler_available}
                      id={`inline-${type}-1`}
                    />
                  </div>
                ))}
              </Form>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
